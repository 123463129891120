import React from "react";
import Layout from "../components/layout";


const Contact = () => (
    <Layout>
        <div className="container">
            <h1 className="t2 ComponentSeparation">Contact</h1>
            <p>Contact me here</p>
        </div>

    </Layout>
);

export default Contact;
